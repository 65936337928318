<template>
  <TogglePanel
    title="toolbar"
    :icon="{
      before: 'fa fa-cogs',
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }"
  >
    <draggable v-model="panelToolbar" class="item" handle=".handle">
      <div
        class="row item"
        v-for="(control, ixControl) in panelToolbar"
        :key="ixControl"
      >
        <div class="col-xs-12">
          <div class="control-toggle control-header">
            <span
              class="clicable handle"
              v-on:click.stop.prevent="control.collapsed = !control.collapsed"
            >
              <i class="fa fa-arrows-v" style="color:#888;"></i>
              <i class="control-icon" :class="control.icon"></i>
              {{ control.collapsed ? control.title : $tc("button") }}
            </span>
            <div class="pull-right">
              <span
                class="btn btn-xs"
                v-on:click.stop.prevent="delPanelControl(ixControl)"
              >
                <i class="glyphicon glyphicon-trash"></i>
              </span>
              <span
                class="btn btn-xs"
                v-on:click.stop.prevent="control.collapsed = !control.collapsed"
              >
                <i
                  class="glyphicon"
                  :class="
                    control.collapsed
                      ? 'glyphicon-collapse-down'
                      : 'glyphicon-collapse-up'
                  "
                ></i>
              </span>
            </div>
          </div>
          <div
            class="form-group form-group-sm control-box"
            v-if="!control.collapsed"
          >
            <div class="input-group">
              <div class="input-group-addon btn input-group-addon-select">
                <select
                  :value="control.contentType || 'icon'"
                  @change="
                    setControlContentType(ixControl, $event.target.value)
                  "
                >
                  <option value="icon">{{ $tc("icon") }}</option>
                  <option value="title">{{ $t("title") }}</option>
                  <option value="both">{{ $t("both") }}</option>
                </select>
              </div>
              <input
                class="form-control"
                type="text"
                :value="control.title"
                @input="setControlTitle(ixControl, $event.target.value)"
              />
              <div
                class="input-group-addon btn btn-icon"
                v-if="control.contentType != 'title'"
              >
                <IconLibrary @input="setControlIcon(ixControl, $event)">
                  <template #button="{ toggle }">
                    <i :class="control.icon" @click.stop.prevent="toggle"></i>
                  </template>
                </IconLibrary>
              </div>
            </div>

            <div
              v-for="(action, iAction) in control.on.click.actions"
              :key="iAction"
              style="clear:both"
            >
              <ControlEventActionForm
                eventName="click"
                :control="control"
                :action="action"
                :index="iAction"
                :open="iAction === newActionIndex"
                @delAction="onDelAction(ixControl, $event)"
                @change="onActionChange(ixControl, $event)"
              />
            </div>

            <ControlActionSelector
              style="margin-bottom:-15px;"
              eventName="click"
              :exclude="eventActions(ixControl)"
              :control="control"
              @addAction="addControlAction(ixControl, $event)"
            />
            <div style="padding: 5px 5px 10px;">
              <AlertForm
                @input="confirmation(ixControl, $event)"
                :value="confirmation(ixControl)"
              />
              <ProcessAreaPermissions
                :value="control"
                @input="setControlPermission(ixControl, $event)"
                tooltipNoun="button"
              />
            </div>
          </div>
        </div>
      </div>
    </draggable>
    <div class="new_button">
      <button
        class="btn btn-xs btn-primary"
        @click.stop.prevent="addPanelControl"
      >
        {{ $t("new_button") }}
      </button>
    </div>
  </TogglePanel>
</template>

<script>
import Events from "@/assets/dashboard/events.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlActionSelector from "@/components/editor/control-action-selector.vue";
import ControlEventActionForm from "@/components/editor/control-event-action-form.vue";
import IconLibrary from "@/components/editor/icon-library.vue";
import AlertForm from "@/components/editor/alert-form.vue";
import draggable from "vuedraggable";
import ProcessAreaPermissions from "@/components/process-area-permissions.vue";

export default {
  name: "PanelToolbarForm",
  components: {
    TogglePanel,
    ControlActionSelector,
    ControlEventActionForm,
    IconLibrary,
    AlertForm,
    ProcessAreaPermissions,
    draggable
  },
  data() {
    return {
      newActionIndex: -1
    };
  },
  computed: {
    panelToolbar: {
      set(value) {
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        if (panel) {
          panel.toolbar = value;
          this.saveDraft(panel);
        }
      },
      get() {
        let panel = this.panel || null;
        if (panel) {
          return panel?.toolbar || [];
        }
        return "";
      }
    },
    screenId() {
      return this.$store.getters["dashboard/draft"]?.screenId || null;
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    }
  },
  methods: {
    saveDraft(panel) {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        screenId: this.screenId,
        panelName: panel.name,
        panel: panel,
        setAsCurrent: true
      });
    },
    addPanelControl() {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      toolbar.forEach((control) => {
        control.collapsed = true;
      });
      toolbar.push({
        icon: "fa fa-flag",
        title: this.$t("title"),
        rule: "",
        collapsed: false,
        on: {
          click: Events["click"]
        }
      });
      this.panelToolbar = toolbar;
    },
    delPanelControl(ixControl) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      toolbar.splice(ixControl, 1);
      this.panelToolbar = toolbar;
    },
    addControlAction(ixControl, action) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        let control = toolbar[ixControl];
        let controlEvents = control.on || {};
        controlEvents.click = control.on.click || Events["click"];
        controlEvents.click.actions.push(action);
        control.on = controlEvents;
        this.panelToolbar = toolbar;
        this.newActionIndex = controlEvents.click.actions.length - 1;
      }
    },
    onDelAction(ixControl, ixAction) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        let control = toolbar[ixControl];
        let controlEvents = control.on || {};
        controlEvents.click = control.on.click || Events["click"];
        if (ixAction < controlEvents.click.actions.length) {
          controlEvents.click.actions.splice(ixAction, 1);
          control.on = controlEvents;
          this.panelToolbar = toolbar;
        }
      }
      this.newActionIndex = -1;
    },
    setControlTitle(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        toolbar[ixControl].title = value;
        this.panelToolbar = toolbar;
      }
    },
    setControlIcon(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        toolbar[ixControl].icon = value;
        this.panelToolbar = toolbar;
      }
    },
    setControlContentType(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        toolbar[ixControl].contentType = value;
        this.panelToolbar = toolbar;
      }
    },
    setControlPermission(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      toolbar[ixControl].view_permission = value.view_permission;
      toolbar[ixControl].interaction_permission = value.interaction_permission;
      this.panelToolbar = toolbar;
    },
    onActionChange(ixControl, ixAction) {
      this.panelToolbar = this.panelToolbar;
    },
    eventActions(ixControl) {
      let actions = [];
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        let control = toolbar[ixControl];
        if (control) {
          let evt = control?.on?.click || Events["click"] || null;
          if (evt) {
            actions = evt.actions.map((i) => i.type);
          }
        }
      }
      return actions;
    },
    confirmation(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        let control = toolbar[ixControl];
        if (control) {
          let evt = control?.on?.click || Events["click"] || null;
          if (evt) {
            if (value) {
              evt.confirmation = value;
              this.panelToolbar = toolbar;
            }
            return evt.confirmation || null;
          }
        }
        return null;
      }
    }
  }
};
</script>

<style scoped>
.btn-icon {
  min-width: 45px;
}
.new_button {
  margin-top: 10px;
}
.input-group-addon-select {
  padding: 0;
}
.input-group-addon-select > select {
  border: 0;
  font-size: 85%;
  background: #ffffff;
}
.input-group-addon-select > select:hover {
  cursor: pointer;
  opacity: 0.8;
}
.input-group-addon-select > select:focus {
  border: 0;
  outline-width: 0;
}
.control-header {
  padding: 5px 2px;
  font-weight: 600;
  background: #eee;
  border-radius: 3px;
  margin-top: 1px;
}
.control-box {
  margin: 0;
}
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.control-icon {
  margin-left: 5px;
  min-width: 20px;
}
</style>
