<template>
  <div class="no-print" style="display:inline;">
    <div class="box-tools pull-right">
      <template v-if="customTools && customTools.actions">
        <span
          v-for="(action, index) in customTools.actions"
          :key="index"
          :class="['btn btn-box-tool', action.class || '']"
          :title="action.title"
          @click.stop.prevent="action.run"
        >
          <i v-if="action.icon" v-bind="action.icon"> </i>
        </span>
      </template>

      <!--
        TODO: uncomment this when "New panel"
        command become standard in all panels
       -->
      <!-- <span
        class="btn btn-box-tool"
        :title="$t('new_panel')"
        @click.stop.prevent="onNew"
      >
        <i class="fa fa-file-o"> </i>
      </span> -->

      <span
        class="btn btn-box-tool"
        @click.stop.prevent="$emit('customProperties')"
        :title="$t('panel_properties')"
      >
        <i class="fa fa-gear"></i>
      </span>

      <DashboardDownloadBtn
        class="btn btn-box-tool"
        v-if="screenId"
        :screenId="screenId"
      />

      <span
        class="btn btn-box-tool"
        @click.stop.prevent="onRestore"
        :title="$t('restore_screen')"
      >
        <i class="fa fa-rotate-left"></i>
      </span>

      <span
        type="button"
        class="btn btn-box-tool"
        @click.stop.prevent="onPublish"
        :title="$t('publish_screen')"
        v-if="$can('manage', 'TelaCustomizadaEscrita')"
      >
        <i class="fa fa-cloud-upload"></i>
      </span>

      <span
        type="button"
        class="btn btn-box-tool"
        @click.stop.prevent="onExpand"
        :title="$t('expand_collapse_panel')"
      >
        <i class="fa fa-expand"></i>
      </span>

      <span
        type="button"
        class="btn btn-box-tool"
        v-on:click.prevent.stop="onClose"
        :title="$t('close_editing')"
        data-widget="remove"
      >
        <i class="fa fa-times"></i>
      </span>
    </div>
    <PublishForm
      v-if="panelName && screenId && publish"
      v-on:close="publish = false"
      v-bind:panelName="panelName"
      v-bind:screenId="screenId"
      v-on:saved="onPublished"
    />
  </div>
</template>

<script>
import PanelForm from "@/components/control-sidebar/property-editors/panel-form.vue";
import PublishForm from "@/components/editor/publish-form.vue";
import DashboardDownloadBtn from "@/components/editor/dashboard-download-btn.vue";

import {
  validateTemplate,
  invalidTemplateAlert
} from "@/components/editor/publish-form.vue";

export default {
  name: "PanelEditorToolbar",
  components: {
    PublishForm,
    DashboardDownloadBtn
  },
  props: {
    customTools: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      vuexModules: {
        SynopticPanel: "synoptic" // map here the $store module responsible for the panel
      },
      publish: false,
      panelzIndex: undefined
    };
  },
  computed: {
    canEdit() {
      return (
        this.$can("manage", "TelaCustomizadaAcesso") &&
        this.$route.path.indexOf("/dashboard/screen") >= 0
      );
    },
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    screenId() {
      return this.$parent.screenId || this.$parent.$attrs.screenId || "";
    },
    panelName() {
      return this?.$parent?.panel?.name || "";
    },
    panelComponentType() {
      return this?.$parent?.$options?.name || null;
    },
    vuexModuleName() {
      if (this.panelComponentType) {
        return this?.$parent.$options.name in this.vuexModules
          ? this.vuexModules[this?.$parent.$options.name]
          : null;
      } else {
        return null;
      }
    },
    editablePanel() {
      if (this.vuexModuleName) {
        let module = this.vuexModuleName;
        return this.$store.getters[`${module}/panel`] || null;
      }
      return null;
    }
  },
  watch: {
    publish(n) {
      if (n) {
        if (this?.$parent?.$el && this.$parent.$el.style.zIndex != "") {
          this.panelzIndex = this.$parent.$el.style.zIndex;
        }
        this.$parent.$el.style.zIndex = "";
      } else {
        if (this.panelzIndex !== undefined) {
          this.$parent.$el.style.zIndex = this.panelzIndex;
        }
      }
    }
  },
  methods: {
    onOpen() {
      this.$emit("open");
    },
    onClose() {
      this.$emit("close");
    },
    onProperties() {
      if (this.vuexModuleName) {
        this.$root.$emit("controlSidebar:setContent", PanelForm);
        this.$store.dispatch(`${this.vuexModuleName}/clearSelection`);
      }
    },
    onExpand() {
      if (this.panelName) {
        this.$store.dispatch("dashboard/expand", this.panelName);
      }
    },
    onNew() {
      let self = this;
      if (this.vuexModuleName) {
        let wrapper = document.createElement("div");
        wrapper.innerHTML = self.$t(
          "this_will_reset_all_of_your_unpushed_changes"
        );
        self
          .$swal({
            title: self.$t("are_you_sure"),
            content: wrapper,
            icon: "warning",
            buttons: [self.$t("cancel"), self.$t("confirm")]
          })
          .then(function(option) {
            if (option) {
              let module = self.vuexModuleName;
              self.$store.dispatch(`${module}/create`, self.panelComponentType);
            }
          });
      }
    },
    onPublish() {
      let draft = this.$store.getters["dashboard/draft"] || null;
      if (!draft || !draft?.template) return;
      if (!validateTemplate(draft?.template)) {
        invalidTemplateAlert(this);
        return;
      }
      this.publish = true;
    },
    onPublished(screen) {
      this.$emit("published", screen);
    },
    onRestore() {
      let self = this;
      let draft = this.$store.getters["dashboard/draft"] || null;
      if (draft && draft?.template?.draft?.removed) {
        for (var key in draft?.template?.draft?.removed) {
          let panel = draft.template.draft.removed[key];
          if (panel && panel.template == this.panelComponentType) {
            delete draft.template.draft.removed[key];
            self.$emit("restore", panel);
            return;
          }
        }
      }
      let wrapper = document.createElement("div");
      wrapper.innerHTML =
        self.$t("this_will_reset_all_of_your_unpushed_changes") +
        "<br>" +
        self.$t("you_wont_be_able_to_revert_this");
      self
        .$swal({
          title: self.$t("are_you_sure"),
          content: wrapper,
          icon: "warning",
          buttons: [self.$t("cancel"), self.$t("confirm")]
        })
        .then(function(option) {
          if (option) {
            self.$emit("restore");
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.box-tools > span.btn {
  color: #333;
}

.btn-box-tool {
  padding: 2px 5px;
  width: 24px;
  height: 24px;
  margin-inline: 1px;
}

.box-tools.pull-right .btn-box-tool {
  &:not(.is-active) {
    &:hover {
      background-color: #e6e6e6;
    }

    &:active {
      background-color: #d4d4d4;
    }
  }

  &.is-active {
    background-color: #3c8dbc;

    &:hover {
      background-color: #367fa9;
    }

    &:active {
      background-color: #204d74;
    }

    i {
      color: white;
    }
  }
}

.fa-redo {
  transform: rotateY(180deg);
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
