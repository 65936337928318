<template>
  <div class="self no-print" v-if="toolbar && toolbar.length">
    <div class="box-tools hidden-xs hidden-sm">
      <template v-for="(control, ix) in toolbar">
        <button
          class="btn btn-box-tool"
          v-if="can(control)"
          :key="ix"
          :title="
            canInteract(control)
              ? control.title
              : $t('you_do_not_have_enough_privileges_to_execute_this_action')
          "
          :disabled="!canInteract(control)"
          @click.stop.prevent="onClick(control, ix)"
        >
          <i
            :class="control.icon || 'fa fa-bolt'"
            v-if="
              contentType(control) == 'icon' || contentType(control) == 'both'
            "
          ></i>
          <span
            v-if="
              contentType(control) == 'title' || contentType(control) == 'both'
            "
          >
            {{ control.title }}
          </span>
        </button>
      </template>
    </div>
    <div class="box-tools hidden-md hidden-lg box-dropdown">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-default dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
          style="border: 0; background-color: transparent"
        >
          <span class="caret toolbar-btn-icon"></span>
        </button>
        <ul class="dropdown-menu">
          <li v-for="(control, ix2) in toolbar" :key="ix2">
            <a
              href="#"
              v-if="can(control)"
              @click.stop.prevent="onClick(control)"
              :title="control.title"
            >
              <i :class="control.icon || 'fa fa-bolt'"></i>
              <span>
                {{ control.title }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PanelToolbarCustom",
  props: {
    panel: {
      type: Object,
      required: true,
      default: () => null
    },
    equipment: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      widget_data: null,
      selectedAction: null,
      dialog: {
        src: "",
        ready: false
      }
    };
  },
  computed: {
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    toolbar: function() {
      let toolbar = this.panel?.toolbar ?? null;
      if (this.mode == "viewer" && toolbar) {
        toolbar = toolbar.filter((btn) =>
          btn.view_permission?.length
            ? btn.view_permission.some((pa) => this.hasUserAccessTo(pa))
            : true
        );
      }
      return toolbar;
    },
    ...mapGetters("user", ["hasUserAccessTo"])
  },
  methods: {
    can(action) {
      if (action.rule) {
        return this.$can(action.rule);
      }
      return true;
    },
    canInteract(control) {
      return (
        this.mode == "editor" ||
        (control.interaction_permission?.length
          ? control.interaction_permission.some((pa) =>
              this.hasUserAccessTo(pa)
            )
          : true)
      );
    },
    triggerEventActions(control, actions) {
      (actions || []).forEach((action) => {
        this.$root.$emit("custom_action", {
          context: this,
          source: control,
          event: "click",
          connector: this.connector,
          action: action
        });
      });
    },
    onClick(control, index) {
      if (this.mode == "editor") {
        this.$emit("panelProperties", { toolbar: index });
      } else {
        let evt = control?.on?.click || null;
        if (evt && evt?.actions?.length) {
          if (evt?.confirmation?.enabled) {
            this.$swal(evt.confirmation).then((confirm) => {
              if (confirm) {
                this.triggerEventActions(control, evt.actions);
              }
            });
          } else {
            this.triggerEventActions(control, evt.actions);
          }
        }
      }
    },
    contentType(control) {
      return control?.contentType || "icon";
    }
  }
};
</script>

<style scoped>
.self {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  top: -2px;
}

.box-tools {
  font-size: inherit;
  margin: 0;
  line-height: 1;
  white-space: nowrap;
}

.btn-box-tool {
  height: 24px;
  margin-inline: 1px;
  padding-block: 2px;
  font-size: inherit;
  color: inherit;
}

.box-dropdown {
  display: inline-block;
  vertical-align: top;
  margin-top: -3px;
}

.btn-img {
  width: 20px;
  height: 24px;
  background-size: contain;
  opacity: 0.5;
  background-repeat: no-repeat;
  margin-top: 2px;
}

.btn-img:hover {
  opacity: 0.8;
}

.scrollable-iframe {
  border: 0;
  min-height: 800px;
  overflow: auto;
  width: 100%;
}

.btn-box-tool:hover:not([disabled]) {
  color: #3c8dbc;
}

.toolbar-btn-icon {
  margin: initial;
}

@media (max-width: 360px) {
  .toolbar-btn-icon {
    margin: 0 -6px;
  }
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
