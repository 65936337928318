<template>
  <span
    class="btn btn-xs"
    @click.stop.prevent="onClick()"
    :title="$tc('export')"
  >
    <i class="fa fa-download"></i>
  </span>
</template>

<script>
import DashboardPublisher from "@/components/editor/dashboard-publisher.vue";
export default {
  name: "DashboardDownloadBtn",
  extends: DashboardPublisher,
  props: {
    screenId: {
      type: [Number, String],
      required: true,
      default: () => 0
    }
  },
  data() {
    return {
      screen: null
    };
  },
  methods: {
    // template() {
    //   let screen = this.$store.getters["dashboard/screen"](this.screenId) || {};
    //   if (!screen.template) {
    //     return null;
    //   }
    //   return screen.template;
    // },
    async onClick() {
      // if (!this.template()) {
      //   return;
      // }
      this.screen =
        this.$store.getters["dashboard/screen"](this.screenId) || {};
      let content = await this.content();
      if (!content) return;
      content.origin = this?.screen?.path || "";
      this.$utils.download(
        JSON.stringify(content, null, "\t"),
        "application/json",
        this.screenName() + ".json"
      );
    },
    screenName() {
      let name = this.screen.name;
      if (this.screen.id < 0) {
        name = this.$t("new_screen");
        name = `${name} (${
          -1 * this.screen.id <= 9
            ? "0" + -1 * this.screen.id
            : -1 * this.screen.id
        })`;
      }
      return name;
    }
  }
};
</script>

<style scoped></style>
