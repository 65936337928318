<template>
  <div class="no-print" style="display:inline;">
    <div class="box-tools pull-right">
      <button
        v-if="canEdit"
        type="button"
        class="btn btn-box-tool"
        v-on:click.prevent.stop="onOpen()"
      >
        <i class="fa fa-pencil"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Panels from "@/assets/dashboard/panels.json";
export default {
  name: "PanelToobarViewer",
  computed: {
    canEdit() {
      let panel = this?.$parent?.panel || null;
      let customizable =
        (panel &&
          Panels.find((item) => {
            return panel.template == item.template.template;
          })?.customizable) ||
        false;
      return (
        customizable &&
        this.$can("manage", "TelaCustomizadaAcesso") &&
        this.$route.path.indexOf("/dashboard/screen") >= 0
      );
    },
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    panel() {
      return this.$parent;
    }
  },
  methods: {
    onOpen() {
      this.$emit("open");
    },
    screenshot() {
      let el = this.panel?.$refs?.display?.$el || null;
      if (el) {
        html2canvas(el).then((canvas) => {
          document.body.appendChild(canvas);
        });
      }
    },
    onPrint() {
      let self = this;
      // validates a panel background image
      let bgImgSrc = self?.panel?.panelOptions?.canvas?.src || "";
      if (bgImgSrc) {
        this.$utils.toDataUrl(bgImgSrc, (b64) => {
          self.panelOptions.canvas.src = b64;
          self.$nextTick(() => {
            this.screenshot();
          });
        });
      } else {
        this.screenshot();
      }
    }
  }
};
</script>

<style scoped>
.btn-img {
  width: 20px;
  height: 24px;
  background-size: contain;
  opacity: 0.5;
  background-repeat: no-repeat;
  margin-top: 2px;
}

.btn-img:hover {
  opacity: 0.8;
}
@media print {
  .no-print {
    display: none;
  }
}
</style>
