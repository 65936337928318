<template>
  <EquipmentDashboardPanel
    v-bind:equipment="equipment"
    v-bind:display="display"
    name="sinoptic"
    style="height:500px"
  >
  </EquipmentDashboardPanel>
</template>

<script>
/*
this component displays an equipment panel
*/
import EquipmentDashboardPanel from "@/components/equipment-dashboard-panel.vue";
export default {
  name: "EquipmentDashboardSinglePanel",
  components: {
    EquipmentDashboardPanel
  },
  props: {
    equipment: {
      type: Object,
      required: true
    },
    display: {
      type: Object,
      required: true
    }
  },
  mounted: function() {
    //console.log("User EquipmentDashboardSinglePanel template loaded");
  }
};
</script>

<style></style>
