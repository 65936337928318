<template>
  <div class="me">
    <TogglePanel
      title="panel_properties"
      data-testid="toggle-panel"
      :icon="{
        before: 'fa fa-sliders',
        collapse: 'fa-minus',
        expand: 'fa-plus'
      }"
      v-if="panel"
    >
      <div class="row" style="display:none;">
        <div class="col-xs-12">
          <div class="form-group form-group-sm ">
            <input
              class="form-control"
              type="text"
              :value="panelName"
              data-testid="name"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group form-group-sm property">
            <label class="" for="control_title" style=""
              >{{ $t("title") }}:</label
            >
            <input
              v-if="panel && 'title' in panel"
              class="form-control"
              type="text"
              id="control_title"
              @input="panelTitle = $event.target.value"
              :value="panelTitle"
              data-testid="title"
            />
            <input
              v-else
              class="form-control"
              type="text"
              id="control_title"
              :value="$t('not_available')"
              disabled
              data-testid="title"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group form-group-sm property">
            <label style="">{{ $tc("height") }}</label>
            <div class="input-group input-group-sm">
              <div class="input-group-addon">
                {{ $t("proportional") }}
              </div>
              <input
                class="form-control text-center"
                v-model="proportionalHeight"
                ref="panelHeight"
              />
              <div class="input-group-addon">%</div>
            </div>
          </div>
          <div class="form-group form-group-sm property">
            <div class="input-group">
              <div class="input-group-addon">
                <label> {{ $t("minimum") }} </label>
              </div>
              <input
                type="text"
                class="form-control text-center no-padding"
                v-model="minHeight"
              />
              <div class="input-group-addon">
                px
              </div>
            </div>
          </div>
          <div class="form-group form-group-sm property">
            <div class="input-group">
              <div class="input-group-addon">
                <label> {{ $t("maximum") }} </label>
              </div>
              <input
                type="text"
                class="form-control text-center no-padding"
                v-model="maxHeight"
              />
              <div class="input-group-addon">
                px
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group form-group-sm property">
            <label class="no-select">
              <input
                type="checkbox"
                v-model="scrollBar"
                style="vertical-align: text-bottom;"
              />
              {{ $tc("scroll_bars") }}
            </label>
            <div class="input-group input-group-sm">
              <div class="input-group-addon input-group-addon-scroll">
                {{ $t("vertical") }}
              </div>
              <select class="form-control" v-model="panelVerticalScroll">
                <option value="auto">{{ $t("automatic") }}</option>
                <option value="scroll">{{ $t("always") }}</option>
                <option value="hidden">{{ $t("never") }}</option>
                <option value="unset">{{ $t("standard") }}</option>
              </select>
            </div>
            <div class="input-group input-group-sm">
              <div class="input-group-addon input-group-addon-scroll">
                {{ $t("horizontal") }}
              </div>
              <select class="form-control" v-model="panelHorizontalScroll">
                <option value="auto">{{ $t("automatic") }}</option>
                <option value="scroll">{{ $t("always") }}</option>
                <option value="hidden">{{ $t("never") }}</option>
                <option value="unset">{{ $t("standard") }}</option>
              </select>
            </div>
          </div>
          <div class="form-group form-group-sm property text-center" style="">
            <button class="btn btn-default btn-sm" @click="onApply">
              {{ $tc("apply_to_other_panels") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label style="margin-bottom: 0;vertical-align: middle;"
              >{{ $t("synoptic.background_color") }}:</label
            >
            <ColorPicker
              v-model="panelColor"
              v-bind:pickerStyle="{ left: '0px' }"
              icon="background"
              style="display: inline-block; margin-left: 10px;"
            />
          </div>
        </div>
      </div>
      <SelectMultipleProcessAreas
        v-model="panelProcessAreas"
        :tooltipTitle="$t('titles.panel_access_by_process_area')"
        style="margin-bottom: 10px;"
      />
    </TogglePanel>
    <PanelToolbarForm v-if="customizableToolbar" />
  </div>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ColorPicker from "@/components/editor/color-picker.vue";
import SelectMultipleProcessAreas from "@/components/select-multiple-process-areas.vue";
import PanelToolbarForm from "@/components/control-sidebar/property-editors/panel-toolbar-form.vue";
import Panels from "@/assets/dashboard/panels.json";
export default {
  name: "PanelForm",
  components: {
    TogglePanel,
    ColorPicker,
    SelectMultipleProcessAreas,
    PanelToolbarForm
  },
  data() {
    return {
      originalTitle: "",
      // scrollBar: false,
      previous: {
        style: null,
        heightProportion: 0
      }
    };
  },
  computed: {
    mode() {
      //return this.$store.getters['dashboard/mode']||'viewer';
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    proportionalHeight: {
      set(value) {
        let p = parseInt(value);
        p = isNaN(p) ? 0 : p;
        if (p < 0) p = 0;
        if (p >= 0 && p <= 100) {
          let panel = JSON.parse(JSON.stringify(this?.panel || {}));
          panel.heightProportion = p ? p / 100 : 0;
          this.saveDraft(panel);
          this.$root.$emit("panel:resized", panel.name);
        }
      },
      get() {
        let value =
          this.panel.heightProportion === ""
            ? 0
            : this.panel.heightProportion * 100;
        return value > 0 && value <= 100 ? Math.round(value) : "auto";
      }
    },
    minHeight: {
      set(value) {
        this.setPanelHeight("min-height", value);
        this.saveDraft(this.panel);
        this.$root.$emit("panel:resized", this.panel.name);
      },
      get() {
        return this.getPanelHeight("min-height");
      }
    },
    maxHeight: {
      set(value) {
        this.setPanelHeight("max-height", value);
        this.saveDraft(this.panel);
        this.$root.$emit("panel:resized", this.panel.name);
      },
      get() {
        return this.getPanelHeight("max-height");
      }
    },
    panelVerticalScroll: {
      set(value) {
        this.setPanelVerticalScroll(value);
        this.saveDraft(this.panel);
      },
      get() {
        let panel = this.panel || null; //fake
        if (panel) {
          //scroll|auto|hidden
          return (panel?.style || {})["overflow-y"] || "hidden";
        }
        return "";
      }
    },
    panelHorizontalScroll: {
      set(value) {
        this.setPanelHorizontalScroll(value);
        this.saveDraft(this.panel);
      },
      get() {
        let panel = this.panel || null; //fake
        if (panel) {
          //scroll|auto|hidden
          return (panel?.style || {})["overflow-x"] || "hidden";
        }
        return "";
      }
    },
    panelTitle: {
      set(value) {
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        if (panel) {
          panel.title = value;
          this.saveDraft(panel);
        }
      },
      get() {
        return (this?.panel && this?.panel?.title) || "";
      }
    },
    panelColor: {
      set(value) {
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        if (panel) {
          let style = panel.style || {};
          style["background-color"] = value;
          panel.style = style;
          this.saveDraft(panel);
        }
      },
      get() {
        let panel = this.panel || null;
        if (panel) {
          //scroll|auto|hidden
          return (panel?.style || {})["background-color"] || "#FFFFFF";
        }
        return "";
      }
    },
    scrollBar: {
      set(n) {
        let style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
        if (n) {
          let prv = JSON.parse(JSON.stringify(this?.previous?.style || {}));
          style["overflow-y"] =
            (style || {})["overflow-y"] ||
            (prv || {})["overflow-y"] ||
            "hidden";
          style["overflow-x"] =
            (style || {})["overflow-x"] ||
            (prv || {})["overflow-x"] ||
            "hidden";
        } else {
          this.previous.style = JSON.parse(JSON.stringify(style));
          style["overflow-x"] = "unset";
          style["overflow-y"] = "unset";
        }
        this.panel.style = style;
        this.saveDraft(this.panel);
      },
      get() {
        let overflow = (this?.panel?.style || {})["overflow-y"] || "";
        return overflow && overflow !== "unset";
      }
    },
    panelProcessAreas: {
      set(value) {
        let panel = JSON.parse(JSON.stringify(this.panel));
        panel.view_permission = value;
        this.saveDraft(panel);
      },
      get() {
        return this.panel.view_permission;
      }
    },
    screenId() {
      return this.$route.path.split("/")[4];
    },
    panelName() {
      return this.$route.path.split("/")[5];
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panelStyle() {
      return this?.panel?.style || {};
    },
    customizableToolbar() {
      if (!this.panel) return false;
      return (
        Panels.find((panel) => panel.template.template == this.panel.template)
          ?.customizableToolbar || false
      );
    }
  },
  watch: {
    panelName: {
      handler(n) {
        if (n && this?.panel) {
          // translate it only once
          this.panelTitle = this.$tc(
            (this?.panel && this?.panel?.title) || "",
            1
          );
        }
      },
      immediate: true
    }
  },
  methods: {
    setPanelHeight(prop, value) {
      let style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
      value = (value + "").replace(/px/gi, "");
      if (value === "" || value === "auto" || isNaN(Number(value))) {
        style[prop] = "auto";
      } else {
        style[prop] = (value + "").replace(/px/gi, "") + "px";
      }
      this.$set(this.panel, "style", style);
    },
    getPanelHeight(prop) {
      let value = ((this.panel?.style || {})[prop] || "").replace(/px/, "");
      return value === "" ? "auto" : value;
    },
    setPanelVerticalScroll(value) {
      let style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
      style["overflow-y"] = value;
      this.$set(this.panel, "style", style);
    },
    setPanelHorizontalScroll(value) {
      let style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
      style["overflow-x"] = value;
      this.$set(this.panel, "style", style);
    },
    saveDraft(panel) {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        screenId: this.screenId,
        panelName: this.panelName,
        panel: panel,
        setAsCurrent: true
      });
    },
    onApply() {
      let template = this.$store.getters["dashboard/editorTemplate"] || null;
      let layout = (template && template.layout) || null;
      if (layout) {
        let self = this;
        for (var ixRow in layout) {
          let row = layout[ixRow];
          let lst = [];
          let found = false;
          for (var ixColumn in row || []) {
            let column = row[ixColumn];
            for (var ixPanel in column?.panels || []) {
              let panelName = column?.panels[ixPanel];
              if (panelName == self.panelName) {
                found = true;
              } else {
                lst.push({
                  name: panelName,
                  perc: 100 / column.panels.length / 100
                });
              }
            }
          }
          if (found) {
            let refMap =
              this.$store.getters["dashboard/screenRefMap"](this.screenId) ||
              null;
            if (!refMap) {
              let screen =
                this.$store.getters["dashboard/screen"](this.screenId) || null;
              if (screen?.reference_connectors?.length) {
                refMap = { conn1: screen?.reference_connectors[0].id };
              }
            }
            let connectorId = (refMap && refMap.conn1) || null;
            let connector =
              (connectorId &&
                this.$store.getters["equipmentById"](connectorId)) ||
              null;
            lst.forEach((item) => {
              var panelName = item.name;
              var height = self.proportionalHeight * item.perc;
              if (item.perc < 1) {
                height -= 5; // padding between panels is 10px
              }
              // search for an existing draft panel
              let panel = this.$store.getters["dashboard/draftPanel"]({
                screenId: self.screenId,
                panelName: panelName
              });
              if (!panel && connector) {
                // once there is no draft panel - it creates one based on the selected equipment
                let connectorPanel =
                  connector?.display?.panels?.find(
                    (item) => item.name == panelName
                  ) || null;
                if (connectorPanel) {
                  panel = JSON.parse(JSON.stringify(connectorPanel));
                }
              }
              if (panel) {
                self.setPanelHeight(panel, height);
                self.setPanelHorizontalScroll(self.panelHorizontalScroll);
                self.setPanelVerticalScroll(self.panelVerticalScroll);
                self.$store.dispatch("dashboard/saveDraftPanel", {
                  screenId: self.screenId,
                  panelName: panelName,
                  panel: panel,
                  setAsCurrent: false
                });
              }
            });
            break;
          }
        }
        if (!this.$store.getters["dashboard/expandedPanel"]) {
          this.$root.$emit("dashboard:refresh");
        }
      }
    }
  }
};
</script>

<style scoped>
.me {
  margin-bottom: 10px;
  padding-inline: 15px;
  position: relative;
  background: white;
}

.me .toggle-panel::v-deep .box-header {
  margin-inline: -15px;
}

.property {
  margin-bottom: 5px;
}

.property label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
}

.input-group-addon-scroll {
  min-width: 90px;
  padding: 0;
}

.btn-icon {
  min-width: 45px;
}
.new_button {
  margin-top: 10px;
}
.input-group-addon-select {
  padding: 0;
}
.input-group-addon-select > select {
  border: 0;
  font-size: 85%;
  background: #ffffff;
}
.input-group-addon-select > select:hover {
  cursor: pointer;
  opacity: 0.8;
}
.input-group-addon-select > select:focus {
  border: 0;
  outline-width: 0;
}

.control-header {
  padding: 5px 2px;
  font-weight: 600;
  background: #eee;
  border-radius: 3px;
  margin-top: 1px;
}
.control-box {
  margin: 0;
}
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.control-icon {
  margin-left: 5px;
  min-width: 20px;
}
</style>
