<template>
  <div class="outer-panel">
    <component
      v-if="can"
      :is="WIDGET"
      :style="panelStyle"
      :equipment="equipment"
      :display="display"
      :panelName="panelName"
      :screenId="screenId"
      :title="$t(panelTitle)"
      :panel="panel"
      :mode="mode"
      :template="template"
      :preview="preview"
      :class="`inner-panel __${panelName}__`"
      :isEditing="isEditing"
      @panelProperties="onPanelProperties"
      @initCustomProperties="onInitCustomProperties"
    >
      <template v-slot:toolbar="customTools">
        <template v-if="mode == 'editor'">
          <PanelToolbarEditor
            v-if="isEditing"
            @published="onPublished"
            @restore="onRestore"
            @close="onEditorClose"
            @customProperties="onCustomProperties"
            :customTools="customTools"
          />
          <PanelToolbarViewer v-else @open="onEditorOpen" />
        </template>
        <PanelToolbarCustom
          :panel="panel"
          v-bind:equipment="equipment"
          @panelProperties="onPanelProperties"
        />
      </template>
    </component>
    <EquipmentEmptyPanel v-else />
  </div>
</template>
<script>
const ComponentNotAvailable = () =>
  import("@/components/component-not-available.vue");
const EquipmentTableDetailsReport = () =>
  import("@/components/equipment-table-details-report.vue");
const EquipmentTableAlarmSummary = () =>
  import("@/components/equipment-table-alarm-summary.vue");
const EquipmentTableBasicReport = () =>
  import("@/components/equipment-table-basic-report.vue");
const EquipmentTableBasicInfo = () =>
  import("@/components/equipment-table-basic-info.vue");
const EquipmentTableDataView = () =>
  import("@/components/equipment-table-data-view");
const SynopticPanel = () => import("@/components/synoptic/synoptic-panel.vue");
const EquipmentDataPanel = () =>
  import("@/components/equipment-data-panel.vue");
const EquipmentHistoryPanel = () =>
  import("@/components/equipment-history-panel.vue");
const EquipmentAlarmPanel = () =>
  import("@/components/equipment-alarm-panel.vue");
const EquipmentEmptyPanel = () =>
  import("@/components/equipment-empty-panel.vue");
const EquipmentTableStationPanel = () =>
  import("@/components/equipment-table-station-panel.vue");
const EventsHistoryPanel = () =>
  import("@/components/events-history-panel.vue");
const EquipmentToolBarPanel = () =>
  import("@/components/equipment-toolbar-panel.vue");
const EquipmentHistoryChartPanel = () =>
  import("@/components/equipment-history-chart-panel.vue");
const DashboardTablePanel = () =>
  import("@/components/dashboard-table-panel.vue");
const DashboardRichTextPanel = () =>
  import("@/components/dashboard-rich-text-panel.vue");
const SynopticDisplay = () =>
  import("@/components/synoptic/synoptic-display.vue");
const EquipmentAlarmConfigurationDisplay = () =>
  import("@/components/equipment-alarm-configuration-display.vue");
const EquipmentAlarmHistoryDisplay = () =>
  import("@/components/equipment-alarm-history-display.vue");
const EquipmentHistoryChartDisplay = () =>
  import("@/components/equipment-history-chart-display.vue");
const EquipmentHistoryReportDisplay = () =>
  import("@/components/equipment-history-report-display.vue");
const DashboardImagePanel = () =>
  import("@/components/dashboard-image-panel.vue");
const DeviceListTablePanel = () =>
  import("@/components/device-list-table-panel.vue");
const EquipmentCardPanel = () =>
  import("@/components/equipment-card-panel.vue");
const EquipmentSearchPanel = () =>
  import("@/components/equipment-search-panel.vue");

import InfoBox from "@/components/info-box.vue";

import PanelForm from "@/components/control-sidebar/property-editors/panel-form.vue";
import PanelToolbarEditor from "@/components/panel-toolbar-editor.vue";
import PanelToolbarViewer from "@/components/panel-toolbar-viewer.vue";
import PanelToolbarCustom from "@/components/panel-toolbar-custom.vue";
export default {
  name: "EquipmentDashboardPanel",
  components: {
    EquipmentTableDetailsReport,
    EquipmentTableDataView,
    SynopticPanel,
    EquipmentDataPanel,
    EquipmentHistoryPanel,
    EquipmentAlarmPanel,
    EquipmentEmptyPanel,
    SynopticDisplay,
    EquipmentAlarmConfigurationDisplay,
    EquipmentAlarmHistoryDisplay,
    EquipmentHistoryChartDisplay,
    EquipmentHistoryReportDisplay,
    EquipmentTableAlarmSummary,
    EquipmentTableBasicReport,
    EquipmentTableBasicInfo,
    EquipmentTableStationPanel,
    EventsHistoryPanel,
    EquipmentToolBarPanel,
    EquipmentHistoryChartPanel,
    DashboardTablePanel,
    DashboardRichTextPanel,
    DashboardImagePanel,
    DeviceListTablePanel,
    EquipmentCardPanel,
    EquipmentSearchPanel,
    InfoBox,
    PanelToolbarEditor,
    PanelToolbarViewer,
    PanelToolbarCustom
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => ({})
    },
    display: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: "0"
    },
    screenId: {
      type: [String, Number],
      default: () => 0
    }
  },
  data() {
    return {
      small: false,
      customPanelProperties: null,
      busy: false
    };
  },
  computed: {
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    preview() {
      return this.$route.path.startsWith("/dashboard/screen/view");
    },
    can() {
      if (this.panel) {
        if (this.panel.rule) {
          return this.$can("manage", this.panel.rule);
        }
        return true;
      }
      return false;
    },
    panel: function () {
      return this.mode == "editor"
        ? this.editorPanel || this.connectorPanel
        : this.connectorPanel;
    },
    editorPanel() {
      if (this.mode != "editor") return null;
      // check current one
      // let panel = this.$store.getters["dashboard/currentDraftPanel"] || null;
      // if (panel) {
      //   if (panel.name == this.name) {
      //     return panel;
      //   } else {
      //     // it is not the panel being edited get it from localstorage
      //     let screenId = this.$route.path.split("/")[4];
      //     panel =
      //       this.$store.getters["dashboard/draftPanel"]({
      //         screenId: screenId,
      //         panelName: this.name
      //       }) || null;
      //   }
      //   return panel;
      // }
      // return null;
      let panel = this.$store.getters["dashboard/currentDraftPanel"] || null;
      if (panel && panel.name == this.name) {
        return panel;
      }
      let draft = this.$store.getters["dashboard/draft"] || null;
      return (
        (draft &&
          (draft?.template?.panels || []).find(
            ({ name }) => name == this.name
          )) ||
        null
      );
    },
    connectorPanel() {
      return (
        (this?.display?.panels || []).find((i) => i.name == this.name) || null
      );
    },
    panelName: function () {
      var panel = this.panel;
      return panel.name || this.name;
    },
    panelStyle() {
      let style = {};
      let report =
        this.panel.template == "DashboardTablePanel" && this.media == "print";
      if (this.display.render_version) {
        //let style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
        // important - create a new style with defaults due old screens
        style = {
          "background-color":
            (this?.panel?.style || {})["background-color"] || "#ffffff"
        };
        if (this.media == "print") {
          // print media does not treat device dimensions
          style["overflow-x"] = "unset";
          style["overflow-y"] = "unset";
          style["min-height"] =
            (this?.panel?.style || {})["min-height"] || "auto";
          if (report) {
            style["max-height"] = "auto";
          }
        } else {
          style["overflow-x"] =
            (this?.panel?.style || {})["overflow-x"] || "hidden";
          style["overflow-y"] =
            (this?.panel?.style || {})["overflow-y"] || "hidden";
        }
      } else {
        style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
      }
      if (this.small) {
        // panels are piled up vertically and not side by side horizontally
        if ("min-height" in style) {
          let height = parseInt((style["min-height"] + "").replace(/px/, ""));
          if (window.innerHeight > height) {
            // expand till the device height
            style["min-height"] = "100vh";
            style["max-height"] = "100vh";
          } else {
            // remove limitation
            delete style["min-height"];
            delete style["max-height"];
          }
        }
      } else {
        // make it compatible with old screens
        if ("min-height" in (this?.panel?.style || {})) {
          style["min-height"] = this?.panel?.style["min-height"];
        }
        if ("max-height" in (this?.panel?.style || {}) && !report) {
          style["max-height"] = this?.panel?.style["max-height"];
        }
        if ("min-height" in style && !("background-color" in style)) {
          style["background-color"] = "#ffffff";
        }
      }
      if (this.panel.template == "SynopticPanel") {
        // synoptic panel calculates it by its own
        if (this.isEditing && this.$store.getters["dashboard/expandedPanel"]) {
          delete style["min-height"];
          delete style["max-height"];
        }
      }
      style.opacity = this.busy ? ".6" : "1";
      style.cursor = this.busy ? "wait" : "default";
      return style;
    },
    panelTitle: function () {
      let result = this?.panel?.title
        ? this.$utils.evaluate(
            {
              connector: this.equipment || {}
            },
            this?.panel?.title
          )
        : null;
      return (
        (typeof result == "string" ? result : "") || this?.panel?.title || ""
      );
    },
    WIDGET: function () {
      var components = this.$options.components || {};
      var panel = this.panel;
      var widget = (panel && panel.template) || "ComponentNotAvailable";
      return (
        (widget && widget in components && components[widget]) ||
        ComponentNotAvailable
      );
    },
    isEditing() {
      return this.editorEditPath == this.$route.path;
    },
    template() {
      return this.$store.getters["dashboard/template"](this.screenId);
    },
    editorEditPath() {
      return `/dashboard/screen/edit/${this.screenId}/${this.panelName}`;
    },
    editorViewPath() {
      return `/dashboard/screen/view/${this.screenId}`;
    },
    media() {
      if (this.$store.getters["dashboard/mode"] == "editor") return "screen";
      return this?.$route?.query?.media || this?.template?.media || "screen";
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (to && this.editorEditPath == to.path) {
          this.initEditor();
        }
      },
      immediate: true
    }
  },
  methods: {
    switchToViewer(screen) {
      let path = `/dashboard/screen/view/${
        (screen && screen.id) || this.screenId
      }`;
      if (path != this.$route.path) {
        this.$router.push(path);
        this.$root.$emit("controlSidebar:setContent", null);
      }
      this.$root.$emit("controlSidebar:setContent", null);
      this.$store.dispatch("dashboard/resetCurrentDraftPanel");
    },
    switchToEditor() {
      let path = this.editorEditPath;
      if (path != this.$route.path) {
        let screenId = path.split("/")[4];
        let panelName = path.split("/")[5];
        if (screenId && panelName && panelName == this.name) {
          // do I have a valid draft template?
          let draft = this.$store.getters["dashboard/draft"] || null;
          if (!draft || draft.screenId != screenId) {
            let _initDraft = async function (ctx) {
              // if (ctx.$store.getters["dashboard/mode"] != "editor") {
              //   ctx.$store.commit("dashboard/SET_MODE", "editor");
              // }
              await ctx.$store.dispatch("dashboard/initDraft", screenId);
              ctx.$store.dispatch("dashboard/initEditor", {
                panelName: panelName
              });
              ctx.$router.push(path);
            };
            _initDraft(this);
            return;
          }
          this.$router.push(path);
        }
      }
    },
    initEditor() {
      if (this.mode == "editor") {
        let screenId = this.$route.path.split("/")[4];
        let panelName = this.$route.path.split("/")[5];
        if (screenId && panelName && panelName == this.name) {
          this.customPanelProperties = null;
          this.$store.dispatch("dashboard/resetCurrentDraftPanel");
          // is there already a persited draft of this panel
          let draftPanel = this.$store.getters["dashboard/draftPanel"]({
            screenId: screenId,
            panelName: panelName
          });
          if (draftPanel) {
            // set it as current
            this.$store.commit("dashboard/SET_CURRENT_DRAFT_PANEL", draftPanel);
          } else {
            // there is no draft, so set one
            if (this.connectorPanel) {
              this.$store.dispatch("dashboard/saveDraftPanel", {
                screenId: screenId,
                panelName: panelName,
                panel: this.connectorPanel,
                setAsCurrent: true
              });
            }
          }
          this.$root.$emit("controlSidebar:setContent", PanelForm);
        }
      }
    },
    onInitCustomProperties(entry) {
      if (
        this.isEditing &&
        entry.panelName ==
          this.$store.getters["dashboard/currentDraftPanel"].name
      ) {
        this.customPanelProperties = entry.propertyEditor;
        return;
      }
      this.customPanelProperties = null;
    },
    onCustomProperties() {
      if (this.isEditing) {
        if (this.customPanelProperties) {
          this.$store.dispatch("synoptic/clearSelectedControls");
          this.$root.$emit(
            "controlSidebar:setContent",
            this.customPanelProperties,
            { show: true }
          );
        } else {
          this.$root.$emit("controlSidebar:setContent", PanelForm);
        }
      }
    },
    onPanelProperties(param) {
      if (this.isEditing) {
        const paramActions = {
          toolbar(index) {
            const focusLightUp = (el) => {
              el.classList.add("lightup-shadow");
              el.addEventListener("animationend", removeClass);

              function removeClass() {
                el.classList.remove("lightup-shadow");
              }
            };

            let controls = document.querySelectorAll(
              ".control-sidebar .control-toggle"
            );
            if (!controls.length) {
              setTimeout(() => {
                controls = document.querySelectorAll(
                  ".control-sidebar .control-toggle"
                );
                controls[index].scrollIntoViewIfNeeded(true);
                focusLightUp(controls[index]);
              }, 500);
            } else {
              controls[index].scrollIntoViewIfNeeded(true);
              focusLightUp(controls[index]);
            }
          }
        };
        this.$root.$emit("controlSidebar:setContent", PanelForm);
        if (typeof param == "object") {
          let key = Object.keys(param)[0];
          if (key in paramActions) {
            paramActions[key](param[key]);
          }
        }
      }
    },
    onPublished(screen) {
      //this.ready = false;
      this.switchToViewer(screen);
      //this.$store.dispatch("synoptic/setPanel", null);
      // if (screen && screen?.id && this?.equipment?.id) {
      //   this.$store.dispatch("setEquipmentDisplay", {
      //     equipmentId: this.equipment.id,
      //     screenId: screen.id
      //   });
      // }
    },
    onRestore() {
      let _fetch = async function (ctx, screenId) {
        ctx.busy = true;
        let template = await ctx.$store.dispatch("dashboard/fetchTemplate", {
          screenId: screenId,
          updateStatus: false
        });
        if (template) {
          ctx.$store.commit("dashboard/SAVE_DRAFT", {
            screenId: screenId,
            template: null
          });
          ctx.$root.$emit("refreshPage");
        }
        ctx.busy = false;
      };
      let draftPanel =
        this.$store.getters["dashboard/currentDraftPanel"] || null;
      if (draftPanel) {
        if (draftPanel) {
          _fetch(this, this.screenId);
        }
      }
      return;
    },
    onEditorClose() {
      this.switchToViewer();
    },
    onEditorOpen() {
      this.switchToEditor();
    },
    updateSize() {
      this.small = window.innerWidth < 768;
    }
  },
  mounted() {
    this.$emit("mounted", this.panel.name);
  },
  beforeDestroy() {}
};
</script>

<style scoped>
.outer-panel {
  width: 100%;
  max-width: 100vw;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 10px;
}

.inner-panel {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
}
</style>
<style>
.lightup-shadow {
  animation: lightUpShadow 1s 0.3s ease forwards;
}

@keyframes lightUpShadow {
  0%,
  100% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0 0 6px 2px rgb(60 141 188 / 45%);
  }
}
</style>
