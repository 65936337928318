<template>
  <div>
    <div class="row">
      <div class="col-lg-7">
        <EquipmentDashboardPanel
          v-bind:equipment="equipment"
          v-bind:display="display"
          name="sinoptic"
        >
        </EquipmentDashboardPanel>
      </div>
      <div class="col-lg-5">
        <EquipmentDashboardPanel
          v-bind:equipment="equipment"
          v-bind:display="display"
          name="equipment_history"
        >
        </EquipmentDashboardPanel>
        <EquipmentDashboardPanel
          v-bind:equipment="equipment"
          v-bind:display="display"
          name="alarms"
        >
        </EquipmentDashboardPanel>
      </div>
    </div>
  </div>
</template>

<script>
/*
this component displays sinoptic + history + alarms panels
*/
import EquipmentDashboardPanel from "@/components/equipment-dashboard-panel.vue";
export default {
  name: "EquipmentDashboardBasic",
  components: {
    EquipmentDashboardPanel
  },
  props: {
    equipment: {
      type: Object,
      required: true
    },
    display: {
      type: Object,
      required: true
    }
  },
  mounted: function() {
    //console.log("User EquipmentDashboardBasic template loaded");
  }
};
</script>

<style lang="scss" scoped></style>
