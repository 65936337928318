<template>
  <section>
    <div v-if="isReady">
      <div v-if="hasPermission">
        <component
          :is="WIDGET"
          v-bind:equipment="equipment"
          v-bind:display="display"
          v-bind:screenId="selectedScreenId"
        />
      </div>
      <InfoBox v-else preset="unauthorized" />
    </div>
    <div class="row" v-else>
      <div class="col-md-6 col-md-offset-3">
        <DashboardNotFound v-if="dashboardError" />
        <div v-else class="overlay overlay-loading">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </div>
    </div>
    <CustomActionManager
      v-if="!hasEventManager"
      v-bind:connector="equipment"
      @openScreen="setScreen"
    />
  </section>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import DashboardEquipmentBase from "@/views/private/DashboardEquipmentBase.vue";
import ComponentNotAvailable from "@/components/component-not-available.vue";
import EquipmentDashboardBasic from "@/equipment-dashboard-templates/equipment-dashboard-basic.vue";
import EquipmentDashboardCustom from "@/equipment-dashboard-templates/equipment-dashboard-custom.vue";
import EquipmentDashboardFull from "@/equipment-dashboard-templates/equipment-dashboard-full.vue";
import EquipmentDashboardSinglePanel from "@/equipment-dashboard-templates/equipment-dashboard-single-panel.vue";
import DashboardPrintout from "@/equipment-dashboard-templates/dashboard-printout.vue";

import DashboardNotFound from "@/components/dashboard-not-found.vue";
import CustomActionManager from "@/components/custom-action-manager.vue";
import InfoBox from "@/components/info-box.vue";

import { mqttTopic } from "@/services/equipment.js";
import protocols from "@/services/protocols.js";

export default {
  name: "DashboardEquipment",
  extends: DashboardEquipmentBase,
  components: {
    PageHeader,
    ComponentNotAvailable,
    EquipmentDashboardBasic,
    EquipmentDashboardCustom,
    EquipmentDashboardFull,
    EquipmentDashboardSinglePanel,
    DashboardPrintout,
    InfoBox,
    DashboardNotFound,
    CustomActionManager
  },
  computed: {
    userEquipmentDashboardTemplate: function() {
      let tplName =
        this.$root.config.equipment_dashboard_template ||
        "EquipmentDashboardCustom";
      return tplName;
    },
    WIDGET: function() {
      if (this.media == "print") {
        return this.$options.components.DashboardPrintout;
      } else {
        var components = this.$options.components || {};
        var tpl =
          this.userEquipmentDashboardTemplate || "ComponentNotAvailable";
        return (tpl in components && components[tpl]) || ComponentNotAvailable;
      }
    },
    hasPermission() {
      if (!this.$can("manage", "EquipamentoAcesso")) return false;
      if (this.$route.path.startsWith("/dashboard/screen")) return true;
      return this.$store.getters["user/hasUserAccessTo"](
        (this?.screen() || {})?.process_area?.id
      );
    },
    isMQTT() {
      // return isMQTT(this.equipment); // it seems to be wrongly cached by vue, while under anonymous mode
      return this?.equipment?.protocol?.is_mqtt_protocol || false;
    },
    hasEventManager() {
      // whether there is already action handler instance, do not allow its own
      return this.$route.path.startsWith("/dashboard/search");
    }
  },
  watch: {
    display: {
      handler(n) {
        if (n) {
          this.updateParent();
          this.parseMQTTConnectorTopics();
          if (this.mqttConnectorTopics) {
            this.setupProtocol();
          } else {
            this.dataMonitor(true);
          }
        }
      },
      deep: true
    }
  },
  methods: {
    updateParent() {
      let tpl = this.equipmentTemplate();
      if (tpl) {
        // update header custom links or hide it
        // begin: test:
        // this.$root.$emit("dashboard:page_header", {
        //   show: true,
        //   options: [
        //     {
        //       label: "Contato",
        //       target: "https://hitecnologia.com.br",
        //       icon: "fa fa-user",
        //       style: {
        //         color: "#666"
        //       }
        //     }
        //   ]
        // });
        // end
        if (tpl?.page_header) {
          this.$root.$emit("dashboard:page_header", tpl.page_header);
        }
        // update custom sidebar / or hide it
        if (tpl?.page_side_bar) {
          this.$root.$emit("dashboard:page_side_bar", tpl.page_side_bar);
        }
      }
    },
    allowDataMonitor() {
      return (
        this.$store.getters["dashboard/mode"] != "editor" &&
        !this.$route.path.startsWith("/dashboard/screen") &&
        !this.isMQTT
      );
    },
    dataMonitor(option) {
      if (option) {
        if (!this.allowDataMonitor()) {
          // turn off any monitoring eventually already triggered
          this.dataMonitor(false);
          return;
        }
        // regular data pooling
        if (!this._dataRefreshTimer) {
          // begin - test only
          // return; // manual refresh;
          // this.$root.config.dashboard.refreshInterval = 5000;
          // end
          let interval =
            this?.$root?.config?.dashboard?.refreshInterval || 30000;
          this._dataRefreshTimer = setInterval(
            () => {
              if (this._dataRefreshTimer) {
                this.refreshDashboard();
                // validate interval changes
                if (
                  interval !=
                  (this?.$root?.config?.dashboard?.refreshInterval || 30000)
                ) {
                  this.dataMonitor(false);
                  this.$nextTick(() => {
                    this.dataMonitor(true); // force new config reload
                  });
                }
              }
            },
            interval,
            this
          );
        }
      } else {
        clearInterval(this._dataRefreshTimer);
        this._dataRefreshTimer = null;
      }
    },
    setupProtocol() {
      if (
        this.dashboardMode == "editor" ||
        this.mode == "editor" ||
        this.protocol ||
        !this?.$root?.config?.mqtt?.websocket?.host ||
        !this?.$root?.config?.mqtt?.websocket?.port
      )
        return;

      const handlerStatusChange = (status) => {
        this.$store.commit("SET_BROKER_STATUS", status);
        if (status == "READY") {
          this.protocol.setup({
            ...this?.$root?.config?.mqtt,
            ...{
              topics: Object.keys(this.mqttConnectorTopics).map((t) => `${t}/#`)
            }
          });
        } else if (status == "CONNECTED") {
          this.dataMonitor(false);
        }
      };

      this.protocol = protocols.MQTT({
        onStatusChanged: handlerStatusChange.bind(this),
        onMessage: (msg, info) => {
          if (!info) return;
          let entry;
          let topic = info.destinationName.replace(
            /\/(connector_state|alarm_state|completed_data_acquisition|completed_data_write_cycle)/g,
            ""
          );
          let data = (this.dataList || []).find((i) => mqttTopic(i) == topic);
          let connector = this.connectorList.find(
            ({ mqtt_topic_prefix }) => mqtt_topic_prefix == topic
          );

          if (data) {
            if (/alarm_state/.test(info.destinationName)) {
              entry = {
                id: msg.id,
                ...msg
              };
              entry.last_transition_at = new Date(
                msg?.last_transition_at || null
              ).toISOString();
              this.$store.dispatch("dashboard/setAlarmValue", entry);
            } else {
              entry = {
                id: data.id,
                value: msg.value
              };
              if (msg?.timestamp) {
                entry.date_time = new Date(msg?.timestamp).toISOString();
                entry.restore = {
                  id: data.id,
                  date_time: entry.date_time,
                  value: entry.value
                };
              }
              this.$store.commit("dashboard/SET_DATA_VALUE", entry);
            }
          } else if (/connector_state/.test(info.destinationName)) {
            let connector = (
              this.$store.getters["dashboard/connectorList"] || []
            ).find(({ mqtt_topic_prefix }) => mqtt_topic_prefix == topic);
            if (connector) {
              entry = {
                id: connector.id,
                ...msg
              };
              this.$store.dispatch("dashboard/setConnectorValue", entry);
            }
          } else if (/completed_data_acquisition/.test(info.destinationName)) {
            // it is only valid for modbus connectors
            // console.log("completed_data_acquisition");
            if (
              this._dataRefreshTimer ||
              !connector ||
              connector?.protocol?.is_mqtt_protocol
            )
              return;
            // it skips connection status - since it will be handle by a different topic
            this.refreshDashboard(connector.id, true);
          } else if (/completed_data_write_cycle/.test(info.destinationName)) {
            // it is only valid for modbus connectors
            if (
              this._dataRefreshTimer ||
              !connector ||
              connector?.protocol?.is_mqtt_protocol
            )
              return;
            entry = { ...(msg || { data_ids: [] }) };
            // console.log(`completed_data_write_cycle ${entry.data_ids}`);
            this.$root.$emit("data:sync", entry);
          }
        }
      });
      return;
    },
    setScreen({ screenId, connectorId, actionParams }) {
      let url = `/dashboard/equipment/${connectorId || "screen"}/${screenId}`;
      if (url != this.$route.path) {
        this.actionParams = actionParams;
        if (Object.keys(actionParams || {}).length) {
          localStorage.setItem("_cdim", JSON.stringify(actionParams));
          url = this.$utils.buildUrlSafe(url, { _cdim: "" });
        }
        this.$router.push(url);
        this.$nextTick(() => {
          this.setupDataList();
        });
      }
    },
    parseMQTTConnectorTopics() {
      this.mqttConnectorTopics = null; // non reactive
      if (this.dashboardMode == "editor" || this.mode == "editor") return;
      if (!this.source) return;
      let topics = {};
      let instanceIds = [];
      if (this.isMQTTAvailable(this.equipment)) {
        instanceIds.push(parseInt(this?.equipment.id));
      }
      instanceIds = instanceIds.concat(
        Object.values(this?.source?.models || {}).reduce(
          (p, c) => p.concat((c || []).map((id) => parseInt(id))),
          instanceIds
        )
      );

      let dataIds = this?.source?.resourceIds.map(({ id }) => parseInt(id));
      this.dataList.forEach((data) => {
        if (
          dataIds.indexOf(parseInt(data.id)) >= 0 &&
          this.isMQTTAvailable(data.device?.connector) &&
          instanceIds.indexOf(parseInt(data.device?.connector?.id)) == -1
        ) {
          instanceIds.push(parseInt(data.device.connector.id));
        }
      });
      instanceIds = this.$utils.distinct(instanceIds);
      this.connectorList
        .filter(
          ({ id, mqtt_topic_prefix }) =>
            instanceIds.indexOf(parseInt(id)) >= 0 && mqtt_topic_prefix
        )
        .forEach(({ id, mqtt_topic_prefix }) => {
          topics[mqtt_topic_prefix] = id;
        });

      this.mqttConnectorTopics = Object.keys(topics).length ? topics : null;
    },
    isMQTTAvailable(connector) {
      return (
        connector &&
        (connector?.protocol?.is_mqtt_protocol ||
          this?.$root?.config?.mqtt?.modbus_enabled) &&
        connector.mqtt_topic_prefix
      );
    }
  },
  created() {
    this._dataRefreshTimer = null;
  },
  beforeDestroy() {
    if (this.protocol) {
      this.protocol.destroy();
      this.protocol = null;
    }
    this.$root.$off("dashboard/dataMonitor", this.dataMonitor);
    this.dataMonitor(false);
    this.$store.dispatch("unselectEquipment");
  },
  mounted() {
    if (this.equipment) {
      this.$store.commit("SET_EQUIPMENT_ID", this.equipment.id);
      this.$store.commit("SET_DEVICE_ID", "");
      if (this.templateReady) {
        this.dataMonitor(true);
      }
    }
    this.$root.$on("dashboard/dataMonitor", this.dataMonitor);
  }
};
</script>

<style scoped>
.overlay-loading {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  text-align: center;
  margin-top: 2%;
  color: #607d8b;
  width: 100%;
}
</style>
